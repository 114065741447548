<template>
  <div class="outer-page">
    <x-table :no-data-text="CA('solidManure_check') ? '暂无数据' : '暂无数据查看权限'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @search="search" @add="add" @page-change="pageChange" @loadExpend="loadExpend" @page-size-change="pageSizeChange" @custom="custom"></x-table>
    <Modal v-model="modal.show" :title="modal.title" :width="1200" @on-visible-change="modalChange">
      <Form ref="form" :model="form" :label-width="140" :rules="rules">
        <Row>
          <Col span="8">
          <FormItem label="图片上传">
            <upload-image :readonly="modal.detail" :max="10" :clear-flag="!modal.show" v-model="form.fileUrl" v-show="!modal.detail||(modal.detail&&form.fileUrl)"></upload-image>
            <img v-show="!(!modal.detail||(modal.detail&&form.fileUrl))" src="/imgs/empty/empty.png" width="100" height="100" alt="" />
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="收粪人签字图片">
            <upload-image :readonly="modal.detail" :max="1" :clear-flag="!modal.show" v-model="form.solid.receiveSignImg" v-show="!modal.detail||(modal.detail&&form.solid.receiveSignImg)"></upload-image>
            <img v-show="!(!modal.detail||(modal.detail&&form.solid.receiveSignImg))" src="/imgs/empty/empty.png" width="100" height="100" alt="" />
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="记录人签字图片">
            <upload-image :readonly="modal.detail" :max="1" :clear-flag="!modal.show" v-model="form.recordUserSignImg" v-show="!modal.detail||(modal.detail&&form.recordUserSignImg)"></upload-image>
            <img v-show="!(!modal.detail||(modal.detail&&form.recordUserSignImg))" src="/imgs/empty/empty.png" width="100" height="100" alt="" />
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
          <FormItem label="存储时长(天)" prop="storageDuration">
            <Input :readonly="modal.detail" placeholder="请输入存储时长" v-model="form.storageDuration" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="运出时间" prop="transportOutTime">
            <DatePicker type="date" :readonly="modal.detail" placeholder="请选择运出时间" v-model="form.transportOutTime" style="width: 80%"></DatePicker>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
          <FormItem label="存栏数" prop="onLandNums">
            <Input :readonly="modal.detail" placeholder="请输入存栏数" v-model="form.onLandNums" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="收集工艺" prop="dealWay">
            <Select :disabled="modal.detail" placeholder="请选择收集工艺" v-model="form.solid.dealWay" style="width: 80%" disabled>
              <Option v-for="(item, index) in recordList" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
          <FormItem label="能繁母猪(头)" prop="sowsNum">
            <Input :readonly="modal.detail" placeholder="请输入能繁母猪(头)" v-model="form.sowsNum" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="仔猪头数(头)" prop="pigletNum">
            <Input :readonly="modal.detail" placeholder="请输入仔猪头数(头)" v-model="form.pigletNum" style="width: 80%"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
          <FormItem label="处理方式" prop="fecalTreatmentNo">
            <Select :disabled="modal.detail" placeholder="请选择处理方式" v-model="form.solid.fecalTreatmentNo" style="width: 80%" label-in-value @on-change="handleChange">
              <Option v-for="(item, index) in recordsList" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="消纳利用土地面积" prop="utilizeLandArea">
            <Input :readonly="modal.detail" placeholder="请输入消纳利用土地面积" v-model="form.solid.utilizeLandArea" style="width: 80%"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
          <FormItem label="运出量（m³）" prop="transportOutNum">
            <Input :readonly="modal.detail" placeholder="请输入运出量（m³）" v-model="form.solid.transportOutNum" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="收粪人名称" prop="receiveUserName">
            <Input style="width: 80%" :readonly="modal.detail" placeholder="请输入收粪人名称" v-model="form.solid.receiveUserName"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
          <FormItem label="收粪人电话" prop="receiveMobile">
            <Input :readonly="modal.detail" placeholder="请输入收粪人电话" v-model="form.solid.receiveMobile" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="委托处理机构名称" prop="organization">
            <Input :readonly="modal.detail" placeholder="请输入委托处理机构名称" v-model="form.organization" style="width: 80%"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
          <FormItem label="转运用户名称" prop="transportUserName">
            <Input :readonly="modal.detail" placeholder="请输入转运用户名称" v-model="form.transportUserName" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="设备运行情况">
            <Select :disabled="modal.detail" placeholder="请选择设备运行情况" v-model="form.deviceRunSituation" style="width: 80%" label-in-value>
              <Option value="0">异常</Option>
              <Option value="1">正常</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
          <FormItem label="到达地点" prop="reachPlace">
            <Input :readonly="modal.detail" placeholder="请输入到达地点" v-model="form.solid.reachPlace" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="备注">
            <Input :readonly="modal.detail" type="textarea" :rows="4" style="width: 80%" v-model="form.remark"></Input>
          </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit" v-if="!modal.detail">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
    <Modal :width="300" v-model="isDelete" title="是否删除" @on-ok="deletes" @on-cancel="
        () => {
          this.isDelete = false;
        }
      ">
      <p>确定删除该条消息？</p>
    </Modal>
    <Modal :width="600" v-model="rejectModal.show" title="驳回" @on-ok="rejectOk" @on-cancel="
        () => {
          this.rejectModal.show = false;
        }
      ">
      <Form ref="form" :model="rejectModal.model" :label-width="140">
        <Row>
          <Col span="24">
          <FormItem label="驳回原因" prop="rejectDesc">
            <Input :readonly="rejectModal.detail" placeholder="请输入驳回原因" v-model="rejectModal.model.rejectDesc" type="textarea" :rows="4" style="width: 80%"></Input>
          </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import pictureView from '@/components/picture_view'
import CU from '@/common/util'
export default {
  name: '',
  components: {
    pictureView,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: '所属镇街',
            minWidth: 180,
            tooltip: true,
            align: 'center',
            key: 'areaName',
          },
          {
            title: '图片',
            align: 'center',
            width: 100,
            render: (h, { row }) => {
              if (!row.fileUrl) return <span>--</span>
              return (
                <div style="width:60px;height:60px;padding:4px">
                  <img
                    style="width:100%;height:100%"
                    src={this.getImgUrl(
                      row.servicePath,
                      row.fileUrl.indexOf(',') != -1
                        ? row.fileUrl.split(',')[0]
                        : row.fileUrl
                    )}
                    onClick={() =>
                      this.lookPicture(
                        row.fileUrl.indexOf(',') != -1
                          ? row.fileUrl.split(',')[0]
                          : row.fileUrl
                      )
                    }
                  />
                </div>
              )
            },
          },
          {
            title: '养殖场名称',
            tooltip: true,
            align: 'center',
            minWidth: 140,
            key: 'companyName',
            fixed: 'left',
          },
          {
            title: '存储时长（天）',
            tooltip: true,
            align: 'center',
            minWidth: 140,
            key: 'storageDuration',
          },
          {
            title: '运出时间',
            tooltip: true,
            align: 'center',
            minWidth: 140,
            key: 'transportOutTime',
            render: (h, { row }) => {
              let str = row.transportOutTime
                ? row.transportOutTime.substring(0, 10)
                : ''
              return <span>{str}</span>
            },
          },
          {
            title: '存栏数(头)',
            tooltip: true,
            align: 'center',
            minWidth: 140,
            key: 'onLandNums',
          },
          {
            title: '能繁母猪(头)',
            tooltip: true,
            align: 'center',
            minWidth: 140,
            key: 'sowsNum',
          },
          {
            title: '仔猪头数(头)',
            tooltip: true,
            align: 'center',
            minWidth: 140,
            key: 'pigletNum',
          },
          {
            title: '收集工艺',
            tooltip: true,
            minWidth: 140,
            align: 'center',
            key: 'dealWay',
            render: (h, { row }) => {
              if (row.solid.dealWayName) {
                return <span>{row.solid.dealWayName}</span>
              } else {
                return <span>--</span>
              }
            },
          },
          {
            title: '处理方式',
            tooltip: true,
            minWidth: 140,
            align: 'center',
            key: 'fecalTreatmentName',
            render: (h, { row }) => {
              if (row.solid.fecalTreatmentName) {
                return <span>{row.solid.fecalTreatmentName}</span>
              } else {
                return <span>--</span>
              }
            },
          },
          {
            title: '运出量（m³）',
            tooltip: true,
            minWidth: 140,
            align: 'center',
            key: 'transportOutNum',
            render: (h, { row }) => {
              if (row.solid.transportOutNum) {
                return <span>{row.solid.transportOutNum}</span>
              } else {
                return <span>--</span>
              }
            },
          },
          {
            title: '收粪方名称',
            tooltip: true,
            minWidth: 140,
            align: 'center',
            key: 'receiveUserName',
            render: (h, { row }) => {
              if (row.solid.receiveUserName) {
                return <span>{row.solid.receiveUserName}</span>
              } else {
                return <span>--</span>
              }
            },
          },
          {
            title: '收粪方电话',
            tooltip: true,
            minWidth: 140,
            align: 'center',
            key: 'receiveMobile',
            render: (h, { row }) => {
              if (row.solid.receiveMobile) {
                return <span>{row.solid.receiveMobile}</span>
              } else {
                return <span>--</span>
              }
            },
          },
          {
            title: '委托处理机构',
            tooltip: true,
            align: 'center',
            minWidth: 140,
            key: 'organization',
          },
          {
            title: '转运人',
            minWidth: 140,
            tooltip: true,
            align: 'center',
            key: 'transportUserName',
          },
          {
            title: '驳回原因',
            minWidth: 140,
            tooltip: true,
            align: 'center',
            key: 'rejectDesc',
          },
          {
            title: '上报人',
            minWidth: 140,
            tooltip: true,
            align: 'center',
            key: 'userName',
          },
          {
            title: '上报时间',
            minWidth: 140,
            tooltip: true,
            align: 'center',
            key: 'createTime',
          },
          {
            title: '状态',
            tooltip: true,
            minWidth: 140,
            align: 'center',
            fixed: 'right',
            key: 'status',
            render: (h, { row }) => {
              if (row.status || row.status == 0) {
                if (this.CA('professionalsManure_confirm')) {
                  if (row.status == 0) {
                    return <span>待确认</span>
                  } else if (row.status == 1) {
                    return <span>已提交</span>
                  } else if (row.status == 2) {
                    return <span>已确认</span>
                  } else if (row.status == 3) {
                    return <span>已驳回</span>
                  } else if (row.status == 4) {
                    return <span>已申请</span>
                  }
                } else if (this.CA('professionalsManure_reject')) {
                  if (row.status == 1) {
                    return <span>待确认</span>
                  } else if (row.status == 2) {
                    return <span>已确认</span>
                  } else if (row.status == 3) {
                    return <span>已驳回</span>
                  } else if (row.status == 4) {
                    return <span>修改申请</span>
                  }
                }
              }
            },
          },
          {
            title: '操作',
            width: 250,
            fixed: 'right',
            render: (h, { row }) => {
              return (
                <div>
                  {<a onClick={() => this.expert(row)}>导出报告</a>}
                  {this.CA('solidManure_confirm') &&
                    (row.status == 0 || row.status == 3) && (
                      <Poptip
                        confirm
                        transfer
                        title="确认后不可更改"
                        on-on-ok={() => this.confirm(row, 1)}
                      >
                        <a style="margin-left:10px">确认</a>
                      </Poptip>
                    )}
                  {this.CA('solidManure_confirm') && row.status == 2 && (
                    <Poptip
                      confirm
                      transfer
                      title="确认申请修改吗"
                      on-on-ok={() => this.editUp(row, 4)}
                    >
                      <a style="margin-left:10px">申请修改</a>
                    </Poptip>
                  )}
                  {this.CA('solidManure_reject') && row.status == 4 && (
                    <Poptip
                      confirm
                      transfer
                      title="是否同意修改申请"
                      cancel-text="不同意"
                      ok-text="同意"
                      on-on-ok={() => this.editUp(row, 0)}
                      on-on-cancel={() => this.editUp(row, 2)}
                    >
                      <a style="margin-left:10px">修改申请</a>
                    </Poptip>
                  )}
                  {this.CA('solidManure_reject') && row.status == 1 && (
                    <Poptip
                      confirm
                      transfer
                      title="确认后不可更改"
                      on-on-ok={() => this.confirm(row, 2)}
                    >
                      <a style="margin-left:10px">确认</a>
                    </Poptip>
                  )}
                  {this.CA('solidManure_reject') &&
                    (row.status == 1 || row.status == 2) && (
                      <a
                        style="margin-left:10px"
                        onClick={() => this.reject(row, 3)}
                      >
                        驳回
                      </a>
                    )}
                  {this.CA('solidManure_edit') &&
                    (row.status == 0 || row.status == 3) && (
                      <a
                        style="margin-left:10px"
                        on-click={() => this.edit(row)}
                      >
                        编辑
                      </a>
                    )}
                  {this.CA('solidManure_view') && (
                    <a
                      style="margin-left: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      详情
                    </a>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        title: '',
        show: false,
        submitLoading: false,
      },
      rejectModal: {
        title: '',
        show: false,
        submitLoading: false,
        model: {},
      },
      form: {
        id: '',
        areaName: '',
        storageDuration: '',
        transportOutTime: '',
        onLandNums: '',
        manureType: 1, // 1 固体 2液体
        pigletNum: '',
        sowsNum: '',
        solid: {
          dealWay: '',
          fecalTreatmentName: '',
          fecalTreatmentNo: '',
          reachPlace: '',
          transportOutNum: '',
          receiveUserName: '',
          receiveMobile: '',
          receiveSignImg: '',
          utilizeLandArea: '',
        },
        deviceRunSituation: '',
        organization: '',
        transportUserName: '',
        recordUserSignImg: '',
        remark: '',
      },

      rules: {
        name: [{ required: true, message: '请填写联系人' }],
        farmerName: [{ required: true, message: '请填写企业名称' }],
        tel: [
          { required: true, message: '请填写联系电话' },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message)
                return
              }
              callback()
            },
            message: '手机号格式不正确',
            trigger: 'change',
          },
        ],
        farmerAddress: [{ required: true, message: '请填写养殖地址' }],
        scaleInfo: [{ required: true, message: '请选择规模类型' }],
        typeInfo: [{ required: true, message: '请选择养殖品种' }],
        rejectDesc: [{ required: true, message: '请输入驳回原因' }],
      },
      picture: {
        show: false,
        src: '',
      },
      farmListInfo: [],
      servicePathInfo: '',
      deleteId: '',
      isDelete: false,
      recordList: [],
      recordsList: [],
      //省市区列表
      areaList: [],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    config() {
      let config = {
        filter: {
          loadExpend: {
            loadExpendName: '导出',
            ca: 'solidManure_export',
          },
          custom: {
            filter: [
              {
                name: '全部确认',
                icon: '',
                status: 1,
                prompt: '确定全部确认吗？',
                ca: 'solidManure_confirm',
              },
              {
                name: '全部确认',
                icon: '',
                status: 2,
                prompt: '确定全部确认吗？',
                ca: 'solidManure_reject',
              },
              {
                name: '全部驳回',
                icon: '',
                status: 3,
                prompt: '确定全部驳回吗？',
                ca: 'solidManure_reject',
              },
            ],
          },
          width: 220,
          filterBox: [
            {
              conditionName: '养殖场名称',
              component: 'input',
              field: 'companyName',
              defaultValue: '',
            },
            {
              conditionName: '上报时间',
              component: 'dateShortcuts',
              type: 'daterange',
              field: 'createTime',
            },
            {
              conditionName: '镇街',
              component: 'cascader',
              field: 'areaCode',
              defaultValue: [],
              data: this.areaList,
              isLoadData: true,
              loadData: this.loadData,
            },
            {
              conditionName: '状态',
              component: 'select',
              field: 'statusStr',
              defaultValue: '',
              data: this.CA('solidManure_confirm')
                ? [
                    { value: '0', title: '待确认' },
                    { value: '1,2', title: '已确认' },
                    { value: '3', title: '已驳回' },
                  ]
                : [
                    { value: '1', title: '待确认' },
                    { value: 2, title: '已确认' },
                    { value: '3', title: '已驳回' },
                  ],
              parameterField: 'value',
              showField: 'title',
              clearable: true,
            },
            {
              conditionName: '运出时间',
              component: 'date',
              type: 'daterange',
              field: 'transportOutTime',
              defaultValue: '',
            },
          ],
        },
        page: this.page,
      }
      return config
    },
  },
  methods: {
    //收集工艺列表
    getRecordList() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '25',
      }).then((res) => {
        this.recordList = res.list
      })
    },
    getList() {
      if (!this.CA('solidManure_check')) return
      this.table.loading = true
      if (!this.search_data.statusStr) {
        this.search_data.statusStr = this.CA('professionalsManure_confirm')
          ? '0,1,2,3,4'
          : '1,2,3,4'
      }
      this.$post(this.$api.FARM_ADMINISTRATE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        manureType: 1, //1 固体 2液体
        enterpriseType: 2,
        ...this.search_data,
      })
        .then((res) => {
          res.list.map((item) => {
            if (typeof item.solid == 'string')
              this.$set(item, 'solid', {
                dealWay: '',
                fecalTreatmentName: '',
                fecalTreatmentNo: '',
                transportOutNum: '',
                receiveUserName: '',
                receiveMobile: '',
                receiveSignImg: '',
              })
          })
          this.table.data = res.list
          this.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    search(value) {
      console.log(value)
      let n
      if (value.areaCode) {
        for (let i = 0; i < value.areaCode.length; i++) {
          n = value.areaCode[value.areaCode.length - 1]
        }
        let obj = {
          ...value,
          areaCode: n,
        }
        this.search_data = obj
        this.getList()
      } else {
        let obj = {
          ...value,
        }
        this.search_data = obj
        this.getList()
      }
    },
    add() {
      this.modal = {
        show: true,
        title: '新增',
        submitLoading: false,
        detail: false,
      }
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      this.form.fileUrl = this.getImgUrl(row.servicePath, row.fileUrl)
      this.form.solid.receiveSignImg = this.getImgUrl(
        row.servicePath,
        row.solid.receiveSignImg
      )
      this.form.recordUserSignImg = this.getImgUrl(
        row.servicePath,
        row.recordUserSignImg
      )
      this.modal = {
        show: true,
        title: '编辑',
        submitLoading: false,
        detail: false,
      }
    },
    delete(id) {
      this.isDelete = true
      this.deleteId = id
    },
    deletes() {
      this.$post(this.$api.FARM_ADMINISTRATE.DELETE, {
        id: this.deleteId,
      }).then(() => {
        this.$Message.success('删除成功！')
        this.getList()
      })
    },
    //单条导出
    expert(row) {
      let url = this.$api.EXPORT.FARMADMINISTRATE + `?id=${row.id}&manureType=1`
      this.$downloads(url, `${row.companyName}固体粪污利用记录报告`)
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: '500100',
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
          if (item.hasChild == 'true') {
            item.loading = false
            item.children = []
          }
        })
        this.areaList = res
      })
    },
    loadData(item, callback) {
      item.loading = true
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name
            item.value = item.id
          })
          item.children = res
          callback()
        })
        .finally(() => {
          item.loading = false
        })
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = { ...this.form }
        let url
        if (params.id) {
          url = this.$api.FARM_ADMINISTRATE.UPDATE
        } else {
          url = this.$api.FARM_ADMINISTRATE.ADD
        }

        this.modal.submitLoading = true
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? '修改成功！' : '增加成功！')
            this.getList()
            this.modal.show = false
          })
          .finally(() => {
            this.modal.submitLoading = false
          })
      })
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },
    modalChange(visible) {
      if (visible) return
      this.form = {
        id: '',
        areaName: '',
        storageDuration: '',
        transportOutTime: '',
        onLandNums: '',
        manureType: 1, // 1 固体 2液体
        pigletNum: '',
        sowsNum: '',
        solid: {
          dealWay: '',
          fecalTreatmentName: '',
          fecalTreatmentNo: '',
          transportOutNum: '',
          receiveUserName: '',
          receiveMobile: '',
          receiveSignImg: '',
          reachPlace: '',
          utilizeLandArea: '',
        },
        deviceRunSituation: '',
        organization: '',
        transportUserName: '',
        recordUserSignImg: '',
        remark: '',
      }
      this.$refs.form.resetFields()
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      }
    },
    //导出
    loadExpend() {
      let params = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        manureType: 1, //1 固体 2液体
        enterpriseType: 2,
        ...this.search_data,
      }
      let url = this.$api.FARM_ADMINISTRATE.MANURE_EXPORT
      this.$download(url, params, '固体粪污利用记录表')
    },
    //详情
    checkInfo(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      this.form.fileUrl = this.getImgUrl(row.servicePath, row.fileUrl)
      this.form.solid.receiveSignImg = this.getImgUrl(
        row.servicePath,
        row.solid.receiveSignImg
      )
      this.form.recordUserSignImg = this.getImgUrl(
        row.servicePath,
        row.recordUserSignImg
      )
      this.modal = {
        show: true,
        title: '详情',
        submitLoading: false,
        detail: true,
      }
    },
    // 确定
    confirm(row, status) {
      this.$post(this.$api.FARM_ADMINISTRATE.CONFIRM, {
        ids: row.id,
        status,
      }).then((res) => {
        if (res.code == 200 && this.CA('solidManure_confirm')) {
          this.$Message.success('已确认')
          this.getList()
        } else if (res.code == 200 && this.CA('solidManure_reject')) {
          this.$Message.success('操作成功')
          this.getList()
        } else {
          this.$Message.success('操作失败')
        }
      })
    },
    //处理方式
    getRecordsList() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '530',
      }).then((res) => {
        this.recordsList = res.list
      })
    },
    handleChange(o) {
      if (o) {
        this.form.solid.fecalTreatmentName = o.label
      }
    },
    reject(row, status) {
      let param = { ...row }
      param.status = status
      this.rejectModal.model = param
      this.rejectModal.show = true
    },
    editUp(row, status) {
      let param = { ...row }
      param.status = status
      this.rejectModal.model = param
      this.rejectModal.model.rejectDesc = '修改申请'
      this.rejectOk()
    },
    rejectOk() {
      this.$post(this.$api.FARM_ADMINISTRATE.CONFIRM, {
        ids: this.rejectModal.model.id,
        status: this.rejectModal.model.status,
        rejectDesc: this.rejectModal.model.rejectDesc,
      }).then((res) => {
        if (res.code == 200) {
          this.$Message.success('操作成功')
          this.rejectModal.show = false
          this.getList()
        } else {
          this.$Message.warning('操作失败')
        }
      })
    },
    custom(data) {
      let ids =
        data.ca == 'solidManure_reject'
          ? this.table.data
              .filter((item) => item.status == 1)
              .map((itm) => itm.id)
              .join(',')
          : this.table.data
              .filter((item) => item.status == 0 || item.status == 3)
              .map((itm) => itm.id)
              .join(',')
      if (ids) {
        if (data.name == '全部驳回') {
          this.reject({ id: ids }, data.status)
        } else if (data.name == '全部确认') {
          this.confirm({ id: ids }, data.status)
        }
      } else {
        this.$Message.warning(`当前页暂无可${data.name}内容`)
      }
    },
  },
  mounted() {
    this.getList()
    this.getRecordList()
    this.getRecordsList()
    this.getAreaList()
  },
}
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>
